import { Genders } from "../../api/api.types";
import { Slot } from "../../pages/BookAppointmentPage/BookAppointmentPage.types";
import { Service } from "../service/service.types";

export enum GuardianTypes {
  owner = "owner",
  other = "other",
}

export type CustomerAppointmentDetails = {
  id: string;
  details: CustomerDetails;
  serviceOverride?: Service;
  surgeryDetails?: SurgeryDetails;
  allowMarketing?: boolean;
  paymentType?: PaymentTypes;
  guardianType?: GuardianTypes;
  guardianDetails?: GuardianDetails;
  screeningId: string;
  covidQuestionsFinished?: boolean;
};

export interface SurgeryDetails {
  name: string;
  address: string;
}

export enum Province {
  England = "England",
  Scotland = "Scotland",
  NorthernIreland = "NorthernIreland",
  Wales = "Wales",
}

export interface Address {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  province: Province;
}

export interface BusinessDay {
  isOpen?: boolean;
  startTime?: number;
  endTime?: number;
}

export interface BusinessHours {
  mon?: BusinessDay;
  tue?: BusinessDay;
  wed?: BusinessDay;
  thu?: BusinessDay;
  fri?: BusinessDay;
  sat?: BusinessDay;
  sun?: BusinessDay;
}

export interface Travel {
  distance: string;
  startLat: string;
  startLon: string;
}

export interface Pharmacy {
  id: string;
  name: string;
  address?: Address;
  businessHours?: BusinessHours;
  travel?: Travel;
  phone?: string;
}

export enum BookingTypes {
  owner = "owner", // self
  otherIncludingOwner = "otherIncludingOwner", // myself and others
  otherExcludingOwner = "otherExcludingOwner", // other people
}

export interface Appointment {
  id?: string;
  service: Service;
  pharmacy: Pharmacy;
  bookingType: BookingTypes;
  owner: CustomerAppointmentDetails;
  other: CustomerAppointmentDetails[];
  bookingDetailsFinished: boolean;
  bookedSlot: Slot;
  appointmentComplete: boolean;
  traversalId?: string
}

export interface CustomerDetails {
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: Genders;
  address1: string;
  address2?: string;
  postcode: string;
  city: string;
  email: string;
  phone: string;
  nhsNumber?: string;
}

export interface CustomerNHSNumber {
  nhsNumber?: string;
}

export interface GuardianDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export enum PaymentTypes {
  exempt = "exempt",
  voucher = "voucher",
  payment = "payment",
}
