import { addHours, addYears, parse, subHours } from "date-fns";
import { getNow } from "../../utils/utils";

export interface SlotInput {
  isFlu: boolean;
  locationId: string;
  numberOfBookings?: number;
  paymentType?: string
}

// TODO: this is NOT OK, must be moved to database

const maxFluDate = parse("12-13", "MM-dd", new Date());

const isFluDisabled = (process.env.REACT_APP_FLUVAC_DISABLED === 'true');

export function checkIfServiceIsDisabled({ isFlu, locationId }: SlotInput) {
  if (!isFlu || !isFluDisabled) return false;
  return !checkIfIsMobileVan(locationId);
}

export function getSlotMinimumDate({ isFlu, locationId, numberOfBookings, paymentType }: SlotInput): Date {
  const today = getNow();
  const isMobileVan = checkIfIsMobileVan(locationId);
  const minFluDate = ( (paymentType && (paymentType=== 'exempt' || paymentType === 'exempt_payment')) && (numberOfBookings && numberOfBookings >= 1)) ? parse("10-03", "MM-dd", new Date()) : parse("09-09", "MM-dd", new Date());
  // Flu vaccines are available differently
  if (isFlu && !isMobileVan) {
    const isDuringSeason = today >= minFluDate && today < maxFluDate;
    if (!isDuringSeason) {
      const shouldBeNextYear = today > maxFluDate;
      if (shouldBeNextYear) {
        return addYears(minFluDate, 1);
      }
      const isUnder48Hours = today > subHours(minFluDate, 48);
      if (!isUnder48Hours) {
        return minFluDate;
      }
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const endsUnder48Hours = today > subHours(maxFluDate, 48);
    if (endsUnder48Hours) {
      return addYears(minFluDate, 1);
    }
  }

  if (isMobileVan){
    return today;
  }

  return addHours(today, 48);
}

export function getSlotMaximumDate({
  isFlu,
  locationId,
}: SlotInput): Date | undefined {
  const today = getNow();
  const isMobileVan = checkIfIsMobileVan(locationId);

  if (isFlu && !isMobileVan) {
    const shouldBeNextYear = today > subHours(maxFluDate, 48);
    if (shouldBeNextYear) {
      return addYears(maxFluDate, 1);
    }
    return maxFluDate;
  }
}

export function checkIfIsMobileVan(locationId: string) {
  const locationIdsString = process.env.REACT_APP_VAN_LOCATIONS || "";
  if (!locationIdsString) return false;
  const locationIds = locationIdsString.split(",");
  return locationIds.includes(locationId);
}
